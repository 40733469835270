<template>
  <div>
    <div id='user-view'>
      <v-row>
        <v-col cols='12' lg='4' xl='3'>
          <CustomerDetailBlock2 :customer-data='customerData'
                                :customer-loading='getCustomerLoading' />
        </v-col>
        <v-col cols='12' lg='8' xl='9'>
          <v-card>
            <v-card-title>
              <span>{{ opdData.room_name }}</span>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class='me-2 d-none d-md-block'
                color='accent'
                @click='printDraftOPD'
              >
                {{ $t('draftOPD') }}
              </v-btn>
              <v-btn
                outlined
                class='me-2 d-none d-md-block'
                v-if="opdData.check_update_id == '1'"
                color='warning'
                @click='isEditRoom = true'
              >
                {{ $t('changeDoctorRoom') }}
              </v-btn>
              <v-btn
                fab
                icon
                outlined
                class='me-2 d-block d-md-none'
                v-if="opdData.check_update_id == '1'"
                color='warning'
                @click='isEditRoom = true'
              >
                <v-icon>{{ icons.mdiSwapHorizontal }}</v-icon>
              </v-btn>
              <v-btn outlined class='me-2 d-none d-md-block' color='info' @click='saveOPD'>
                {{ $t('Initial_OPD_data_record') }}
              </v-btn>
              <v-btn
                outlined
                class='me-2 d-block d-md-none'
                color='info'
                fab
                icon
                :loading='saveOPDLoading'
                :disabled='saveOPDLoading'
                @click='saveOPD'
              >
                <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
              </v-btn>
              <v-btn outlined color='primary' class='d-none d-md-block'
                     @click='isShowCheckHistoryLasted = true'>
                {{ $t('view_old_history') }}
              </v-btn>
              <v-btn
                outlined
                fab
                icon
                color='primary'
                class='d-block d-md-none'
                @click='isShowCheckHistoryLasted = true'
              >
                <v-icon>{{ icons.mdiTextBoxSearchOutline }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols='12'>
                  <p class='font-weight-semibold mb-1'>
                    {{ $t('general_information') }} ({{ $t('history_washing_by') }} :
                    {{ opdData.user_fullname }})
                  </p>
                  <v-divider class='mt-0 mb-2'></v-divider>
                  <v-row>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_t'
                        label='T :'
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        suffix='C.'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_p'
                        label='PR :'
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        suffix='/min.'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_rr'
                        label='RR :'
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        suffix='/min.'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_bp'
                        label='BP :'
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        suffix='/mmHg.'
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_w'
                        :label="$t('weight')"
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        :suffix="$t('kg')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_h'
                        :label="$t('height')"
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        :suffix="$t('cm')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_bmi'
                        label='BMI :'
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols='12' md='6' lg='3'>
                      <v-text-field
                        v-model='opdData.opd_fbs'
                        label='FBS :'
                        dense
                        outlined
                        class='my-0'
                        hide-details
                        suffix='mg/dl'
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <p class='font-weight-semibold mt-3 mb-1'>
                    {{ $t('important_symptoms_current_history') }}
                  </p>
                  <v-divider class='mb-2'></v-divider>

                  <v-row>
                    <v-col cols='12' lg='4' class=''>
                      <v-textarea v-model='opdData.opd_cc' dense label='CC' hide-details outlined
                                  rows='3'></v-textarea>
                    </v-col>
                    <v-col cols='12' lg='4'>
                      <v-textarea
                        v-model='opdData.opd_hpi'
                        dense
                        outlined
                        label='HPI'
                        rows='3'
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols='12' lg='4'>
                      <v-textarea
                        v-model='opdData.opd_pmh'
                        outlined
                        dense
                        label='PMH'
                        rows='3'
                        hide-details
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols='12' class='pt-0'>
                      <v-radio-group v-model='opdData.opd_fag' hide-details class='mt-0' dense row>
                        <label class='me-2 font-weight-semibold'>{{ $t('smoking') }} : </label>
                        <v-radio v-for='(item, index) in smoking' :key='index' :value='item.value'>
                          <template v-slot:label>
                            <span v-if="$i18n.locale == 'th'">
                              {{ item.th }}
                            </span>
                            <span v-if="$i18n.locale == 'en'">{{ item.en }}</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols='12' class='pt-0'>
                      <v-radio-group v-model='opdData.opd_alcohol' hide-details class='mt-0' row
                                     dense>
                        <label class='me-2 font-weight-semibold'>{{ $t('drink_alcohol') }}
                          : </label>
                        <v-radio v-for='(item, index) in booze' :key='index' :value='item.value'>
                          <template v-slot:label>
                            <span v-if="$i18n.locale == 'th'">
                              {{ item.th }}
                            </span>
                            <span v-if="$i18n.locale == 'en'">{{ item.en }}</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <p class='font-weight-semibold mt-3 mb-1'>
                    {{ $t('physical_examination') }}
                  </p>
                  <v-divider class='mb-2'></v-divider>

                  <v-row>
                    <v-col cols='12' lg='6'>
                      <v-textarea v-model='opdData.opd_pe' dense outlined rows='3' label='PE'
                                  hide-details></v-textarea>
                    </v-col>
                    <v-col cols='12' lg='6'>
                      <v-textarea v-model='opdData.opd_ga' dense outlined label='GA' rows='3'
                                  hide-details></v-textarea>
                    </v-col>
                  </v-row>
                  <p class='font-weight-semibold mt-3 mb-1'>
                    {{ $t('diagnosis') }}
                  </p>
                  <v-divider class='mb-2'></v-divider>

                  <v-row>
                    <v-col cols='12'>
                      <v-textarea v-model='opdData.opd_dx' dense outlined label='DX' rows='3'
                                  hide-details></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols='12'>
          <v-card :loading='cartLoading'>
            <v-card-title>
              {{ $t('Checklist') }}{{ $t('a_number') }} {{ opdData.opd_code }}
              <span v-if='serve_code' class='ml-1'>{{ $t('ref_service') }}</span>
              <router-link
                v-if='serve_code'
                class='ml-1 text-decoration-none'
                :to="{ name: 'showDetailService', params: { id: serve_id } }"
              >
                {{ serve_code }}
              </router-link>
              <v-spacer></v-spacer>

              <v-btn color='primary' outlined class='d-none d-md-block'
                     @click='isShowLabHistory = true'>
                {{ $t('view_lab_history') }}
              </v-btn>
              <v-btn color='primary' class='d-block d-md-none' fab icon outlined
                     @click='isShowLabHistory = true'>
                <v-icon>{{ icons.mdiClipboardTextSearchOutline }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class='text-left' width='30'>#</th>
                  <th class='text-left' width='100'>
                    {{ $t('a_number') }}
                  </th>
                  <th class='text-left' width='200px'>
                    {{ $t('examination_list') }}
                  </th>
                  <th class='text-left' width='200px'>
                    {{ $t('examination_detail') }}
                  </th>

                  <th class='text-right' width='100'>
                    {{ $t('examination_fee') }}
                  </th>
                  <th class='text-right' width='100'>
                    {{ $t('doctor_fee') }}
                  </th>
                  <th v-if='statusAdd == 2' class='text-right' width='100'>
                    {{ $t('total') }}
                  </th>
                  <th class='text-center' width='100px'>
                    {{ $t('option') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(item, index) in cartListCheck' :key='index'>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.opdchecking_temp_code }}</td>
                  <td>
                    {{ item.opdchecking_temp_name }}
                    <v-chip
                      v-if="item.checking_set_id == '0' || item.checking_set_id == null"
                      color='success'
                      :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
                      small
                    >
                      {{ $t('general') }}
                    </v-chip>
                    <v-chip
                      v-if="item.checking_set_id == '1'"
                      small
                      color='error'
                      :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
                    >
                      {{ $t('single') }}
                    </v-chip>
                    <v-chip
                      v-if="item.checking_set_id == '2'"
                      small
                      color='warning'
                      :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
                    >
                      {{ $t('series') }}
                    </v-chip>
                  </td>
                  <td>
                    <ol>
                      <li v-for='(i, index) in item.checklist' :key='index'>
                        {{ i.checklist_name }}
                      </li>
                    </ol>
                  </td>
                  <td class='text-right'>
                    <strike v-if='item.opdchecking_temp_discount != 0.0'>
                      {{ +item.opdchecking_temp_discount + +item.opdchecking_temp_cost }}
                    </strike>

                    {{ item.opdchecking_temp_cost }}
                  </td>
                  <td class='text-right'>
                    {{ item.opdchecking_temp_fee }}
                  </td>
                  <td v-if='statusAdd == 2'></td>
                  <td class='text-center'>
                    <v-tooltip color='#212121' top>
                      <template v-slot:activator='{ on, attrs }'>
                        <v-btn icon color='primary' v-bind='attrs'
                               @click='editCartListCheck(item, index)' v-on='on'>
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('edit') }}</span>
                    </v-tooltip>
                    <v-tooltip color='#212121' top>
                      <template v-slot:activator='{ on, attrs }'>
                        <v-btn
                          icon
                          color='error'
                          v-bind='attrs'
                          :loading='removeLoading'
                          :disabled='removeLoading'
                          @click='deleteListCheck(item.opdchecking_temp_id)'
                          v-on='on'
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('delete') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr v-if='statusAdd == 1'>
                  <td>{{ cartListCheck.length + 1 }}</td>
                  <td colspan='2' width='150px'>
                    <v-autocomplete
                      v-model='checking_id'
                      :items='examinationList'
                      :search-input.sync='search'
                      :loading='statusLoadDataChecking'
                      :filter='customFilter'
                      dense
                      auto-select-first
                      :no-data-text='statusText'
                      autofocus
                      hide-details
                      class='my-2'
                      item-value='checking_id'
                      :label="`${$t('examination_list')}`"
                      :placeholder="`${$t('search')}${$t('examination_list')} ${$t('input_three_charector')}`"
                      outlined
                    >
                      <template v-slot:selection='{ item }'>
                        <v-chip
                          v-if="item.checking_set_id == '0' || item.checking_set_id == null"
                          color='success'
                          :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
                          small
                        >
                          {{ $t('general') }}
                        </v-chip>
                        <v-chip
                          v-if="item.checking_set_id == '1'"
                          color='error'
                          :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
                          small
                        >
                          {{ $t('single') }}
                        </v-chip>
                        <v-chip
                          v-if="item.checking_set_id == '2'"
                          small
                          color='warning'
                          :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
                        >
                          {{ $t('series') }}
                        </v-chip>
                        <span class='ml-2'> {{ item.checking_name }} ({{ item.checking_code
                          }})</span>
                      </template>
                      <template v-slot:item='{ item }'>
                        <v-list-item-content>
                          <v-list-item-title> {{ item.checking_name }} ({{ item.checking_code }})
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if="item.checking_set_id == '0' || item.checking_set_id == null"
                            small
                            color='success'
                            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
                          >
                            {{ $t('general') }}
                          </v-chip>
                          <v-chip
                            v-if="item.checking_set_id == '1'"
                            small
                            color='error'
                            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
                          >
                            {{ $t('single') }}
                          </v-chip>
                          <v-chip
                            v-if="item.checking_set_id == '2'"
                            small
                            color='warning'
                            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
                          >
                            {{ $t('series') }}
                          </v-chip>
                        </v-list-item-action>
                      </template>
                    </v-autocomplete>
                  </td>
                  <td colspan='2'>
                    <v-select
                      :label="$t('commission')"
                      v-model='commissionSelect'
                      :items='teamsAndUserList'
                      item-text='teams_name'
                      :placeholder="$t('commission')"
                      item-value='teams_id'
                      dense
                      outlined
                      hide-details
                    ></v-select>
                  </td>
                  <td colspan='2'>
                    <div class='d-flex'>
                      <v-btn
                        color='primary'
                        :loading='addCartLoding'
                        :disabled='addCartLoding'
                        class='me-2 d-none d-md-flex'
                        @click='addListCheck'
                      >
                        {{ $t('add') }}
                      </v-btn>
                      <v-btn
                        color='primary'
                        :loading='addCartLoding'
                        :disabled='addCartLoding'
                        icon
                        class='d-flex d-md-none'
                        @click='addListCheck'
                      >
                        <v-icon>{{ icons.mdiPlus }}</v-icon>
                      </v-btn>
                      <v-btn
                        color='secondary'
                        class='d-none d-md-flex'
                        outlined
                        @click=";(checking_id = ''), (statusAdd = 0)"
                      >
                        {{ $t('cancel') }}
                      </v-btn>
                      <v-btn
                        color='secondary'
                        icon
                        class='d-flex d-md-none'
                        @click=";(checking_id = ''), (statusAdd = 0)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <!-- <tr v-if="statusAdd == 2">
                  <td>{{ indexEdit + 1 }}</td>
                  <td>
                    {{ dataEdit.opdchecking_temp_code }}
                  </td>
                  <td>
                    {{ dataEdit.opdchecking_temp_name }}
                    <v-chip
                      v-if="
                        dataEdit.checking_set_id == '0' ||
                          dataEdit.checking_set_id == null
                      "
                      small
                      color="success"
                      :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
                    >
                      {{ $t("general") }}
                    </v-chip>
                    <v-chip
                      v-if="dataEdit.checking_set_id == '1'"
                      small
                      color="error"
                      :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
                    >
                      {{ $t("single") }}
                    </v-chip>
                    <v-chip
                      v-if="dataEdit.checking_set_id == '2'"
                      small
                      color="warning"
                      :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
                    >
                      {{ $t("series") }}
                    </v-chip>
                  </td>
                  <td>
                    <v-text-field
                      v-model="dataEdit.opdchecking_temp_cost"
                      outlined
                      dense
                      :disabled="dataEdit.checking_set_id == '2'"
                      :label="$t('examination_fee')"
                      hide-details
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="dataEdit.opdchecking_temp_fee"
                      outlined
                      dense
                      :label="$t('doctor_fee')"
                      hide-details
                      type="number"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="dataEdit.opdchecking_temp_discount"
                      outlined
                      dense
                      :disabled="dataEdit.checking_set_id == '2'"
                      :label="$t('discount')"
                      hide-details
                      type="number"
                    ></v-text-field>
                  </td>
                  <td class="text-right">
                    <span class="font-weight-bold primary--text">{{ (+dataEdit.opdchecking_temp_cost ) - (+dataEdit.opdchecking_temp_discount) |formatPrice }}</span>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-btn
                        color="primary"
                        class="me-2"
                        :loading="loadingUpdate"
                        :disabled="loadingUpdate"
                        @click="updateListCheck"
                      >
                        <v-icon>mdi-check</v-icon> {{ $t("confirm") }}
                      </v-btn>
                      <v-btn
                        color="secondary"
                        outlined
                        @click="(checking_id = ''), (statusAdd = 0)"
                      >
                        <v-icon>mdi-delete-outline</v-icon> {{ $t("cancel") }}
                      </v-btn>
                    </div>
                  </td>
                </tr> -->
                <tr v-if='statusAdd == 0'>
                  <td colspan='7'>
                    <v-btn color='primary' outlined class='my-2' @click='statusAdd = 1'>
                      <v-icon class='me-1'>
                        {{ icons.mdiPlus }}
                      </v-icon>
                      {{ $t('add_checklist') }}
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class='mb-2'></v-divider>
            <v-row class='px-2 mb-2'>
              <v-col cols='12' lg='6'>
                <p class='mb-0 font-weight-semibold'>
                  {{ $t('note') }}
                </p>
                <quill-editor ref='myTextEditor' v-model='opdData.opd_comment'
                              :options='editorOption' class='pb-2'>
                </quill-editor>
              </v-col>
              <v-col cols='12' lg='6'>
                <p class='mb-0 font-weight-semibold'>
                  {{ $t('summaryTotal') }}
                </p>
                <v-divider></v-divider>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td class='font-weight-bold text-right'>
                        {{ $t('including_doctor_fees') }}
                      </td>
                      <td class='text-right'>
                        {{ cartCheck.check_sum_fee | formatPrice }}
                      </td>
                    </tr>
                    <tr>
                      <td class='font-weight-bold text-right'>
                        {{ $t('total_examination') }}
                      </td>
                      <td class='text-right'>
                        {{ cartCheck.check_sum | formatPrice }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color='primary' :disabled='cartListCheck.length == 0'
                         @click='isShowNotiMessage = true'>
                    {{ $t('save') }}
                  </v-btn>
                  <v-btn color='secondary' outlined @click='isCancel = true'>
                    {{ $t('cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model='isShowNotiMessage' persistent max-width='410'>
      <v-card>
        <v-card-title class='text-center'>
          <v-spacer></v-spacer>
          <span>
            <v-icon class='me-2' color='warning'>
              {{ icons.mdiAlertOutline }}
            </v-icon>
            <span>{{ $t('noti_edit_opd') }}</span></span
          >
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' :loading='updateCheckLoading' :disabled='updateCheckLoading'
                 @click='saveListCheck'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='isShowNotiMessage = false'>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CheckHistoryLasted
      v-model='isShowCheckHistoryLasted'
      :opd-id='String(opdData.opd_id)'
      :customer-id='opdData.customer_id_pri'
      :loading='getCheckingLoading'
    />
    <LabLasted
      v-model='isShowLabHistory'
      :opd-id='String(opdData.opd_id)'
      :customer-id='opdData.customer_id_pri'
      :loading='getCheckingLoading'
    />
    <CancelBlock :id='opdData.opd_id' v-model='isCancel' path='check/status'
                 @onUpdate='getCheckingLoading' />
    <v-dialog v-model='isEditCheckList' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('edit') }} {{ dataEdit.opdchecking_temp_name }} ({{ dataEdit.opdchecking_temp_name
          }})
          <v-chip
            v-if="dataEdit.checking_set_id == '0' || dataEdit.checking_set_id == null"
            small
            color='success'
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
          >
            {{ $t('general') }}
          </v-chip>
          <v-chip
            v-if="dataEdit.checking_set_id == '1'"
            small
            color='error'
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
          >
            {{ $t('single') }}
          </v-chip>
          <v-chip
            v-if="dataEdit.checking_set_id == '2'"
            small
            color='warning'
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
          >
            {{ $t('series') }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model='dataEdit.opdchecking_temp_cost'
            outlined
            dense
            :disabled="dataEdit.checking_set_id == '2'"
            :label="$t('examination_fee')"
          ></v-text-field>
          <v-text-field
            v-model='dataEdit.opdchecking_temp_fee'
            outlined
            dense
            :label="$t('doctor_fee')"
            type='number'
          ></v-text-field>
          <v-text-field
            v-model='dataEdit.opdchecking_temp_discount'
            outlined
            dense
            :label="$t('discount')"
            type='number'
          ></v-text-field>
          <v-select
            v-model='dataEdit.teams_id'
            :items='teamsAndUserList'
            item-text='teams_name'
            :placeholder="$t('commission')"
            :label="$t('commission')"
            item-value='teams_id'
            dense
            outlined
            hide-details
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color='primary'
            class='me-2'
            :loading='loadingUpdate'
            :disabled='loadingUpdate'
            @click='updateListCheck'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            outlined
            @click="
              ;(checking_id = ''), (statusAdd = 0)
              isEditCheckList = false
            "
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <span class='font-weight-bold text-lg primary--text'
          >{{ $t('total') }}
            {{ (+dataEdit.opdchecking_temp_cost - +dataEdit.opdchecking_temp_discount) | formatPrice
            }}</span
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SelectRoom
      v-model='isEditRoom'
      :opd-data='opdData'
      checkingStatus='checking'
      @updateRoomSuccess='saveChangeRoom'
    />
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiAlertOutline,
  mdiContentSaveOutline,
  mdiTextBoxSearchOutline,
  mdiClipboardTextSearchOutline,
  mdiSwapHorizontal,
} from '@mdi/js'
import { quillEditor } from 'vue-quill-editor'
import CustomerDetailBlock2 from '@/components/basicComponents/CustomerDetailBlock2.vue'
import useChecking from './useChecking'
import { formatPrice } from '@/plugins/filters'
import CheckHistoryLasted from './CheckHistoryLasted.vue'
import SelectRoom from './SelectRoom.vue'
import LabLasted from './LabLasted.vue'
import CancelBlock from '@/components/basicComponents/CancelBlock.vue'

export default {
  components: {
    SelectRoom,
    CustomerDetailBlock2,
    LabLasted,
    CheckHistoryLasted,
    CancelBlock,
    quillEditor,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useChecking(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiAlertOutline,
        mdiContentSaveOutline,
        mdiTextBoxSearchOutline,
        mdiClipboardTextSearchOutline,
        mdiSwapHorizontal,
      },
    }
  },
}
</script>
<style lang='scss'>
@import '@core/preset/preset/apps/user.scss';

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 200px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}
</style>
