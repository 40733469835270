<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card :loading="customerLoading" height="688">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="'primary'"
            :class="'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4 elevation-2"
          >
            <v-img :src="customerDataLocal.customer_image"></v-img>
          </v-avatar>
          <span>
            <span class="me-1">{{ customerDataLocal.customer_id }}</span>
            <span
              v-if="
                customerDataLocal.customer_prefix == '-' ||
                customerDataLocal.customer_prefix == 'null' ||
                customerDataLocal.customer_prefix == null ||
                customerDataLocal.customer_prefix == '' ||
                customerDataLocal.customer_prefix == 'ไม่ระบุ'
              "
            >
              -
            </span>
            <span v-else>{{ customerDataLocal.customer_prefix }}</span> {{ customerDataLocal.customer_fname }}
            {{ customerDataLocal.customer_lname }}
            <span v-if="customerDataLocal.customer_nname && customerDataLocal.customer_nname != 'null'"
              >({{ customerDataLocal.customer_nname }})</span
            >
          </span>
          <v-chip
            v-if="!customerLoading"
            color="primary"
            label
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text mt-2`"
          >
            {{ customerDataLocal.customer_group_name }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-if="customerDataLocal.customer_shop">
                  <td class="font-weight-bold">{{ $t('branch') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_shop }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('id_card') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_idcard }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('age') }} :</td>
                  <td>
                    <span v-if="$i18n.locale == 'th'">
                      {{ customerDataLocal.customer_birthdate | sum_birthdate }}
                    </span>
                    <span v-if="$i18n.locale == 'en'">
                      {{ customerDataLocal.customer_birthdate | sum_birthdateEn }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('gender') }} :</td>
                  <td>
                    <span
                      v-if="
                        customerDataLocal.customer_gender == '-' ||
                        customerDataLocal.customer_gender == 'null' ||
                        customerDataLocal.customer_gender == null ||
                        customerDataLocal.customer_gender == '' ||
                        customerDataLocal.customer_gender == 'ไม่ระบุ'
                      "
                    >
                      -
                    </span>
                    <span v-else>
                      {{ customerData.customer_gender }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('telephone_number') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_tel }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('reward_points') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_point | formatPrice }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('blood_type') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_blood }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('be_allergic') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_allergic ? customerDataLocal.customer_allergic + ',' : ''
                    }}{{ drugList }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('congenital_disease') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_disease }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t('note') }} :</td>
                  <td>
                    {{ customerDataLocal.customer_comment }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiArrowLeftBold } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { sum_birthdate, sum_birthdateEn, formatPrice } from '@/plugins/filters'

export default {
  filters: {
    sum_birthdate,
    sum_birthdateEn,
    formatPrice,
  },

  props: {
    customerData: {
      type: Object,
      default: () => ({}),
    },
    customerLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const customerDataLocal = ref({})
    const drugList = ref('')
    watch(
      () => props.customerLoading,
      newVal => {
        if (!newVal) {
          customerDataLocal.value = JSON.parse(JSON.stringify(props.customerData))
          const dataDrug = customerDataLocal.value.drug.map(item => {
            return item.drug_name
          })
          drugList.value = String(dataDrug)
        }
      },
    )

    return {
      customerDataLocal,
      drugList,
      icons: {
        mdiArrowLeftBold,
      },
    }
  },
}
</script>
