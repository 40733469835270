<template>
  <div>
    <v-dialog
      v-model='isShowCheckHistoryLasted'
      max-width='1300'
      persistent
    >
      <v-card :loading='loadingData'>
        <v-card-title>
          <span
            v-if='loadingData'
            align='center'
          >
            {{ $t('data_loading') }}
          </span>
          <span v-else>
            <span v-if='opdList.length'>
              {{ $t('latest_opd') }}
            </span>
            <span v-else>
              {{ $t('no_data') }}
            </span>
          </span>
          <v-spacer></v-spacer>

          <v-btn
            color='error'
            icon
            @click="$emit('update:is-show-check-history-lasted', false)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if='opdList.length'>
          <v-row>
            <v-col
              cols='12'
              md='3'
            >
              <v-card width='100%'>
                <v-navigation-drawer
                  v-if='!loadingData'
                  height='550px'
                  width='100%'
                  permanent
                >
                  <v-list
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model='selectedItem'
                      color='primary'
                    >
                      <v-list-item
                        v-for='(item, i) in opdList'
                        :key='i'
                        :value='item.opd_id'
                      >
                        <v-list-item-content>
                          <v-list-item-title class='d-flex justify-content-between'>
                            <span>{{ item.opd_code }} <v-icon
                              v-if='item.after||item.before||item.face_chart'
                              color='primary'
                            >{{ icons.mdiImageOutline }}</v-icon></span>
                            <v-spacer></v-spacer>
                            <ConverseDate :date='item.opd_date' />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-navigation-drawer>
              </v-card>
            </v-col>
            <v-col
              cols='12'
              md='9'
            >
              <v-card
                v-if='!loadingData'
                min-height='550'
              >
                <v-card-title>
                  <span>{{ $t('number') }} OPD : {{ opdData.opd_code }}</span>
                  <v-spacer></v-spacer>
                  <span class='d-none d-md-block'>{{ $t('doctor_service')
                    }} : {{ opdData.user_fullname }}</span>
                </v-card-title>
                <v-divider class='mt-0'></v-divider>
                <v-row>
                  <v-col
                    cols='12'
                    lg='6'
                  >
                    <v-card-text>
                      <p class=' font-weight-semibold mb-1'>
                        {{ $t('general_information') }}
                      </p>
                      <v-divider
                        class='mt-0 mb-2'
                      ></v-divider>
                      <v-row>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span> T : </span>{{ opdData.opd_t }} C.
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>PR : </span>{{ opdData.opd_p }} /min.
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>RR : </span>{{ opdData.opd_rr }} /min.
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>BP : </span>{{ opdData.opd_bp }} mmHg.
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>{{ $t('weight') }} : </span>{{ opdData.opd_w }} {{ $t('kg') }}
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>{{ $t('height') }} : </span>{{ opdData.opd_h }} {{ $t('cm') }}
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>BMI : </span>{{ opdData.opd_bmi }}
                        </v-col>
                        <v-col
                          cols='6'
                          lg='3'
                        >
                          <span>FBS : </span>{{ opdData.opd_fbs }} mg/dl
                        </v-col>
                      </v-row>
                      <p class='font-weight-semibold mt-3 mb-1'>
                        {{ $t('important_symptoms_current_history') }}
                      </p>
                      <v-divider class='mb-2'></v-divider>
                      <v-row>
                        <v-col
                          cols='12'
                          md='6'
                          lg='4'
                        >
                          <span>CC : </span>{{ opdData.opd_cc }}
                        </v-col>
                        <v-col
                          cols='12'
                          md='6'
                          lg='4'
                        >
                          <span>HPI : </span>{{ opdData.opd_hpi }}
                        </v-col>
                        <v-col
                          cols='12'
                          md='6'
                          lg='4'
                        >
                          <span>PMH : </span>{{ opdData.opd_pmh }}
                        </v-col>
                      </v-row>
                      <p class='font-weight-semibold mt-3 mb-1'>
                        {{ $t('physical_examination') }}
                      </p>
                      <v-divider class='mb-2'></v-divider>
                      <v-row>
                        <v-col
                          cols='12'
                          lg='6'
                        >
                          <span>PE : </span>{{ opdData.opd_pe }}
                        </v-col>
                        <v-col
                          cols='12'
                          lg='6'
                        >
                          <span>GA : </span>{{ opdData.opd_ga }}
                        </v-col>
                      </v-row>
                      <p class='font-weight-semibold mt-3 mb-1'>
                        {{ $t('diagnosis') }}
                      </p>
                      <v-divider class='mb-2'></v-divider>
                      <v-row>
                        <v-col
                          cols='12'
                        >
                          <span>DX : </span>{{ opdData.opd_dx }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols='12'
                        >
                          <p class='font-weight-semibold'>
                            {{ $t('note') }} :
                          </p>
                          <span v-html='opdData.opd_comment'></span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols='12'
                        >
                          <v-divider></v-divider>
                          <p class=' font-weight-semibold'>
                            {{ $t('picture') }} {{ $t('before') }}
                          </p>
                          <v-row>
                            <v-col
                              v-for='(item,index) in opdData.check_before '
                              :key='index'
                              cols='12'
                              md='3'
                            >
                              <v-img
                                :src='item.opdupload_image'
                                style='cursor:pointer'
                                width='100'
                                height='100'
                                class='rounded-lg elevation-2'
                                @click='urlImage = item.opdupload_image;isShowImage = true'
                              ></v-img>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols='12'
                        >
                          <v-divider></v-divider>
                          <p class=' font-weight-semibold'>
                            {{ $t('picture') }} {{ $t('after') }}
                          </p>
                          <v-row>
                            <v-col
                              v-for='(item,index) in opdData.check_after '
                              :key='index'
                              cols='12'
                              md='3'
                            >
                              <v-img
                                :src='item.opdupload_image'
                                style='cursor:pointer'
                                width='100'
                                height='100'
                                class='rounded-lg elevation-2'
                                @click='urlImage = item.opdupload_image;isShowImage = true'
                              ></v-img>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col
                    cols='12'
                    lg='6'
                    class='pb-0'
                  >
                    <p class='font-weight-semibold mb-1'>
                      {{ $t('examination_list') }}
                    </p>
                    <v-divider></v-divider>
                    <ul>
                      <li
                        v-for='(item, index) in checkList'
                        :key='index'
                      >
                        {{ item.opdchecking_name }} {{ item.opdchecking_code }}
                      </li>
                    </ul>
                    <p class=' font-weight-semibold mb-1'>
                      {{ $t('treatment_item') }}
                    </p>
                    <v-divider></v-divider>
                    <ul>
                      <li
                        v-for='(item, index) in courseList'
                        :key='index'
                      >
                        {{ item.orderdetail_name }} {{ item.orderdetail_amount }}
                        {{ item.orderdetail_unit }}
                      </li>
                    </ul>
                    <p class=' font-weight-semibold mb-1'>
                      {{ $t('list_drug_equipment') }}
                    </p>
                    <v-divider></v-divider>
                    <ul>
                      <li
                        v-for='(item, index) in drugList'
                        :key='index'
                      >
                        {{ item.orderdetail_name }} {{ item.orderdetail_amount }}
                        {{ item.orderdetail_unit }} ({{ item.orderdetail_direction }})
                      </li>
                    </ul>
                    <p>
                      {{ $t('note') }} : {{ opdData.order_comment }}
                    </p>
                    <p>
                      {{ $t('doctor_inspector') }} : {{ opdData.user_fullname }}
                    </p>
                    <v-divider></v-divider>
                    <p class=' font-weight-semibold'>
                      {{ $t('document_important') }}
                    </p>
                    <v-row>
                      <v-col
                        v-for='(item,index) in opdData.check_file'
                        :key='index'
                        cols='12'
                        md='3'
                      >
                        <v-img
                          :src='item.opdupload_image'
                          style='cursor:pointer'
                          width='100'
                          height='100'
                          class='rounded-lg elevation-2'
                          @click='urlImage = item.opdupload_image;isShowImage = true'
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model='isShowImage'>
      <v-card>
        <v-card-text>
          <v-img :src='urlImage'></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='secondary'
            outlined
            @click='isShowImage = false'
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose, mdiImageOutline } from '@mdi/js'
import checkDetail from '@/api/queue/checkDetail'
import customerOpd from '@/api/customer/customerOpd'
import shop from '@/api/shop'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'

export default {
  components: {
    ConverseDate,
  },

  model: {
    prop: 'isShowCheckHistoryLasted',
    event: 'update:is-show-check-history-lasted',
  },
  props: {
    isShowCheckHistoryLasted: {
      type: Boolean,
      default: false,
    },
    opdId: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const checkList = ref([])
    const courseList = ref([])
    const drugList = ref([])
    const opdList = ref([])
    const selectedItem = ref('')
    const opdData = ref({})
    const customerIdLocal = ref('')
    const shop_id_pri = ref('')
    const loadingData = ref(true)
    const isShowImage = ref(false)
    const urlImage = ref('')

    const { checkingGet } = checkDetail

    const { opdCardListByCustomer } = customerOpd

    const { shop: shopGet } = shop

    const getShop = () => {
      shopGet().then(res => {
        shop_id_pri.value = res.shop_id_pri
        getOpdLasted()
      })
    }

    const getOpd = () => {
      checkingGet(selectedItem.value).then(res => {
        checkList.value = res.opdcheck || []
        courseList.value = res.orderlist ? res.orderlist.filter(item => item.orderdetail_type_id == 1) : []
        drugList.value = res.orderlist ? res.orderlist.filter(item => item.orderdetail_type_id == 2) : []
        opdData.value = res
      })
    }

    const getOpdLasted = () => {
      opdCardListByCustomer({
        customer_id_pri: customerIdLocal.value,
        opd_code: '',
        shop_id_pri: shop_id_pri.value,
        status_id: '',
      }).then(res => {
        opdList.value = res
        selectedItem.value = res.length ? res[0].opd_id : null
        loadingData.value = false
      })
    }

    watch([selectedItem], () => {
      if (opdList.value.length) {
        getOpd()
      }
    })

    watch(() => props.isShowCheckHistoryLasted, value => {
      loadingData.value = true
      if (value) {
        customerIdLocal.value = props.customerId
        getShop()
      } else {
        opdList.value = []
      }
    })

    return {
      checkList,
      courseList,
      drugList,
      opdList,
      selectedItem,
      opdData,
      isShowImage,
      urlImage,
      loadingData,

      icons: { mdiClose, mdiImageOutline },
    }
  },
}
</script>
