var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"user-bio-panel"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.customerLoading,"height":"688"}},[_c('v-card-title',{staticClass:"justify-center flex-column"},[_c('v-avatar',{staticClass:"mb-4 elevation-2",class:'v-avatar-light-bg primary--text',attrs:{"color":'primary',"size":"120","rounded":""}},[_c('v-img',{attrs:{"src":_vm.customerDataLocal.customer_image}})],1),_c('span',[_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(_vm.customerDataLocal.customer_id))]),(
              _vm.customerDataLocal.customer_prefix == '-' ||
              _vm.customerDataLocal.customer_prefix == 'null' ||
              _vm.customerDataLocal.customer_prefix == null ||
              _vm.customerDataLocal.customer_prefix == '' ||
              _vm.customerDataLocal.customer_prefix == 'ไม่ระบุ'
            )?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.customerDataLocal.customer_prefix))]),_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_fname)+" "+_vm._s(_vm.customerDataLocal.customer_lname)+" "),(_vm.customerDataLocal.customer_nname && _vm.customerDataLocal.customer_nname != 'null')?_c('span',[_vm._v("("+_vm._s(_vm.customerDataLocal.customer_nname)+")")]):_vm._e()]),(!_vm.customerLoading)?_c('v-chip',{class:("v-chip-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + "-bg primary--text mt-2"),attrs:{"color":"primary","label":""}},[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_group_name)+" ")]):_vm._e()],1),_c('v-card-text',[_c('v-divider'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.customerDataLocal.customer_shop)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('branch'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_shop)+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('id_card'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_idcard)+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('age'))+" :")]),_c('td',[(_vm.$i18n.locale == 'th')?_c('span',[_vm._v(" "+_vm._s(_vm._f("sum_birthdate")(_vm.customerDataLocal.customer_birthdate))+" ")]):_vm._e(),(_vm.$i18n.locale == 'en')?_c('span',[_vm._v(" "+_vm._s(_vm._f("sum_birthdateEn")(_vm.customerDataLocal.customer_birthdate))+" ")]):_vm._e()])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('gender'))+" :")]),_c('td',[(
                      _vm.customerDataLocal.customer_gender == '-' ||
                      _vm.customerDataLocal.customer_gender == 'null' ||
                      _vm.customerDataLocal.customer_gender == null ||
                      _vm.customerDataLocal.customer_gender == '' ||
                      _vm.customerDataLocal.customer_gender == 'ไม่ระบุ'
                    )?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(_vm.customerData.customer_gender)+" ")])])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('telephone_number'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_tel)+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('reward_points'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.customerDataLocal.customer_point))+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('blood_type'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_blood)+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('be_allergic'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_allergic ? _vm.customerDataLocal.customer_allergic + ',' : '')+_vm._s(_vm.drugList)+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('congenital_disease'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_disease)+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('note'))+" :")]),_c('td',[_vm._v(" "+_vm._s(_vm.customerDataLocal.customer_comment)+" ")])])])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }