<template>
  <div>
    <v-dialog
      v-model='isShowLabHistory'
      max-width='1300'
      persistent
    >
      <v-card :loading='loadingData'>
        <v-card-title>
          <span
            v-if='loadingData'
            align='center'
          >
            {{ $t('data_loading') }}
          </span>
          <span v-else>
            <span v-if='opdList.length'>
              {{ $t('late_lab') }}
            </span>
            <span v-else>
              {{ $t('no_data') }}
            </span>
          </span>
          <v-spacer></v-spacer>

          <v-btn
            color='error'
            icon
            @click="$emit('update:is-show-lab-history', false)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if='opdList.length'>
          <v-row>
            <v-col
              cols='12'
              md='3'
            >
              <v-card width='100%'>
                <v-navigation-drawer
                  v-if='!loadingData'
                  height='550px'
                  width='100%'
                  permanent
                >
                  <v-list
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model='selectedItem'
                      color='primary'
                    >
                      <v-list-item
                        v-for='(item, i) in opdList'
                        :key='i'
                        :value='item.opd_id'
                      >
                        <v-list-item-content>
                          <v-list-item-title class='d-flex justify-content-between'>
                            <span>{{ item.opd_code }}</span>
                            <v-spacer></v-spacer>
                            <ConverseDate :date='item.opd_date' />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-navigation-drawer>
              </v-card>
            </v-col>
            <v-col
              cols='12'
              md='9'
            >
              <v-card v-if='!loadingData'>
                <v-card-title>
                  <span>     VN :
                    {{ opdData.opd_code }}</span>
                  <v-spacer></v-spacer>
                  <ConverseDate :date='opdData.opd_date' />
                </v-card-title>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th
                        class='text-left'
                        style='width: 10%'
                      >
                        #
                      </th>
                      <th style='width: 50%'>
                        {{ $t('examination_list') }}
                      </th>
                      <th
                        style='width: 20%'
                        class='text-center'
                      >
                        {{ $t('detectable_value') }}
                      </th>
                      <th
                        style='width: 20%'
                        class='text-center'
                      >
                        {{ $t('reference_value') }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if='!opdData.opdchecklist'>
                      <td
                        colspan='4'
                        class='text-center'
                      >
                        {{ $t('no_information') }}
                      </td>
                    </tr>
                    <tr
                      v-for='(item, index) in opdData.opdchecklist'
                      v-else
                      :key='index'
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{ item.opdchecklist_name }}
                      </td>
                      <td class='text-center'>
                        {{ item.opdchecklist_value }}
                      </td>
                      <td class='text-center'>
                        {{ item.opdchecklist_ref }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import checkDetail from '@/api/queue/checkDetail'
import customerOpd from '@/api/customer/customerOpd'
import shop from '@/api/shop'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'

export default {
  components: {
    ConverseDate,
  },

  model: {
    prop: 'isShowLabHistory',
    event: 'update:is-show-lab-history',
  },
  props: {
    isShowLabHistory: {
      type: Boolean,
      default: false,
    },
    opdId: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const checkList = ref([])
    const courseList = ref([])
    const drugList = ref([])
    const opdList = ref([])
    const selectedItem = ref('')
    const opdData = ref({})
    const customerIdLocal = ref('')
    const shop_id_pri = ref('')
    const loadingData = ref(true)

    const { labHistoryLasted } = checkDetail
    const { opdCardListByCustomer } = customerOpd
    const { shop: shopGet } = shop

    const getShop = () => {
      shopGet().then(res => {
        shop_id_pri.value = res.shop_id_pri
        getOpdLasted()
      })
    }

    const getOpd = () => {
      labHistoryLasted({
        opd_id: selectedItem.value,
      }).then(res => {
        opdData.value = res
      })
    }

    const getOpdLasted = () => {
      opdCardListByCustomer({
        customer_id_pri: customerIdLocal.value,
        opd_code: '',
        shop_id_pri: shop_id_pri.value,
        status_id: '',
      }).then(res => {
        opdList.value = res
        selectedItem.value = res.length ? res[0].opd_id : ''
        loadingData.value = false
      })
    }

    watch([selectedItem], () => {
      if (opdList.value.length) {
        getOpd()
      }
    })

    watch(() => props.isShowLabHistory, value => {
      loadingData.value = true
      if (value) {
        customerIdLocal.value = props.customerId
        getShop()
      } else {
        opdList.value = []
      }
    })

    return {
      checkList,
      courseList,
      drugList,
      opdList,
      selectedItem,
      opdData,
      loadingData,

      icons: { mdiClose },
    }
  },
}
</script>
