import booze_data from '@/@fake-db/data/booze_data.json'
import smoking_data from '@/@fake-db/data/smoking_data.json'
import customer from '@/api/customer/customer'
import checking from '@/api/examination/check'
import check from '@/api/queue/check'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import { useRouter } from '@core/utils'
import { mdiStethoscope, mdiTextBoxSearchOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import teamList from '@/api/teamList'

export default function useChecking() {
  const { teamListGet } = teamList
  const editorOption = {
    placeholder: '',
    modules: {
      toolbar: {
        container: [
          [{ list: 'ordered' }, { list: 'bullet' }], // List
          [{ indent: '-1' }, { indent: '+1' }], // Indent
        ],
        handlers: {
          image(val) {
            if (val) {
              document.querySelector('.quill-img input').click()
            } else {
              this.quill.format('image', false)
            }
          },
        },
      },
    },
  }

  const { route } = useRouter()
  const opdData = ref({})
  const getCheckingLoading = ref(true)
  const getCustomerLoading = ref(false)
  const customerData = ref({})
  const smoking = ref(smoking_data.data)
  const booze = ref(booze_data.data)
  const checkTab = ref(null)
  const tabs = [
    { icon: mdiStethoscope, title: 'opdStarter' },
    { icon: mdiTextBoxSearchOutline, title: 'Check list' },
  ]

  const isShowFaceCart = ref(false)
  const commissionSelect = ref([])

  const statusAdd = ref(false)
  const isEditCheckList = ref(false)
  const cartListCheck = ref([])
  const data_list = ref([])
  const examinationList = ref([])
  const search = ref('')
  const statusLoadDataChecking = ref(false)
  const checking_id = ref('')
  const statusText = ref('')

  const addCartLoding = ref(false)
  const cartLoading = ref(false)
  const cartCheck = ref({})

  const removeLoading = ref(false)

  const dataEdit = ref({})
  const indexEdit = ref(0)
  const serviceDataOpd = ref({})
  const serve_code = ref('')
  const serve_id = ref('')

  const isShowNotiMessage = ref(false)
  const updateCheckLoading = ref(false)

  const loadingUpdate = ref(false)

  const saveOPDLoading = ref(false)

  const isShowCheckHistoryLasted = ref(false)

  const isShowLabHistory = ref(false)

  const isCancel = ref(false)

  const isEditRoom = ref(false)

  const teamsAndUserList = ref([])

  teamListGet().then(res => {
    teamsAndUserList.value = res
    commissionSelect.value = res[0].teams_id
  })

  const {
    checkGet,
    checkBasicSave,
    checkCartAdd,
    checkUpdate,
    checkCartList,
    checkCartRemove,
    checkCartUpdate,
    checkAddToReceipt,
    checkServeGet,
  } = check
  const { customerById } = customer
  const { checkList } = checking

  const addListCheck = () => {
    addCartLoding.value = true
    checkCartAdd({
      opd_id: route.value.query.id,
      checking_id: checking_id.value,
      teams_id: commissionSelect.value,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      store.commit('app/setStatusUpdateHeader')
      addCartLoding.value = false
      getCartCheckList()
      statusAdd.value = 0
      checking_id.value = ''
    })
  }

  const deleteListCheck = opdchecking_temp_id => {
    removeLoading.value = true
    checkCartRemove(route.value.query.id, opdchecking_temp_id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      store.commit('app/setStatusUpdateHeader')
      getCartCheckList()
      removeLoading.value = false
    })
  }

  const getCartCheckList = () => {
    cartLoading.value = true
    checkCartList({
      opd_id: route.value.query.id,
    }).then(res => {
      cartListCheck.value = res.cart || []
      cartCheck.value = res
      cartLoading.value = false
    })
  }

  const customFilter = (item, queryText) => {
    if (item.checking_code && item.checking_name) {
      const textOne = item.checking_code.toLowerCase()
      const textTwo = item.checking_name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    }

    return []
  }

  const getCheckList = () => {
    statusLoadDataChecking.value = true
    checking_id.value = ''
    checkList({
      searchtext: search.value,
      checkgroup_id: '',
      checking_status_id: 1,
      checking_type_id: '',
      active_page: 1,
      per_page: '0',
    }).then(res => {
      examinationList.value = res.data
      statusLoadDataChecking.value = false
      statusText.value = !res.length ? i18n.t('no_information') : i18n.t('input_three_charector')
    })
  }

  checkGet(route.value.query.id).then(res => {
    opdData.value = res
    getCheckingLoading.value = false
    getCustomer(res.customer_id_pri)
    getCartCheckList()
    if (route.value.query.serve_id) {
      getCheckService()
    }
  })

  const saveChangeRoom = () => {
    checkGet(route.value.query.id).then(res => {
      opdData.value = res
      getCheckingLoading.value = false
      getCustomer(res.customer_id_pri)
      getCartCheckList()
      if (route.value.query.serve_id) {
        getCheckService()
      }
    })
  }

  const getCheckService = () => {
    checkServeGet(route.value.query.serve_id).then(async res => {
      serviceDataOpd.value = res
      serve_code.value = res.serve_code
      serve_id.value = res.serve_id
      const tempCourse = []
      let htmlText = ''
      let count = 0
      for (let i = 0; i < res.course.length; i++) {
        const item = res.course[i]
        const courseIndex = tempCourse.findIndex(x => x.course_id == item.course_id)
        if (courseIndex == -1) {
          tempCourse.push(item.course_id)
          htmlText = `${htmlText}${i + 1}. (${item.course_id}) ${item.course_name} <br>`
        } else {
          count += 1
        }
      }
      opdData.value.opd_comment = await htmlText
    })
  }

  const getCustomer = id => {
    getCustomerLoading.value = true
    customerById(id).then(res => {
      customerData.value = res
      getCustomerLoading.value = false
    })
  }

  const editCartListCheck = (data, index) => {
    dataEdit.value = JSON.parse(JSON.stringify(data))
    // dataEdit.value.teams_id = dataEdit.value.teams_id ? dataEdit.value.teams_id : teamsAndUserList.value[0].teams_id
    statusAdd.value = 2
    // commissionSelect.value = dataEdit.value.teams_id ? dataEdit.value.teams_id : teamsAndUserList.value[0].teams_id
    isEditCheckList.value = true
    indexEdit.value = index
  }

  const updateListCheck = () => {
    loadingUpdate.value = true
    checkCartUpdate(dataEdit.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getCartCheckList()
      loadingUpdate.value = false
      isEditCheckList.value = false
      statusAdd.value = 0
      dataEdit.value = {}
    })
  }

  const saveListCheck = () => {
    updateCheckLoading.value = true
    opdData.value.opd_id = route.value.query.id
    checkUpdate(opdData.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      store.commit('app/setStatusUpdateHeader')
      if (!res.order_id_pri) {
        addToReceipt()
      } else {
        updateCheckLoading.value = false
      }
    })
  }

  const addToReceipt = () => {
    checkAddToReceipt(route.value.query.id).then(res => {
      if (res.response) {
        router.push({
          name: 'ShowDetailCheck',
          params: { id: route.value.query.id },
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        store.commit('app/setStatusUpdateHeader')
        updateCheckLoading.value = false
      }
    })
  }

  const saveOPD = () => {
    saveOPDLoading.value = true
    opdData.value.lang = i18n.locale
    opdData.value.opd_id = route.value.query.id
    checkBasicSave(opdData.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })

      if (res.response) {
        router.push({
          name: 'Examination-Room',
        })
      } else {
        saveOPDLoading.value = false
      }
    })
  }

  watch(search, value => {
    if (value && value.length >= 3) {
      statusText.value = i18n.t('data_loading')
      statusLoadDataChecking.value = true
      getCheckList()
    } else {
      examinationList.value = []
      statusText.value = i18n.t('input_three_charector')
    }
  })

  watch(
    () => opdData.value.opd_w,
    value => {
      if (value) {
        opdData.value.opd_bmi = (value / ((opdData.value.opd_h / 100) * (opdData.value.opd_h / 100))).toFixed(2)
      }
    },
  )

  watch(
    () => opdData.value.opd_h,
    value => {
      if (value) {
        opdData.value.opd_bmi = (opdData.value.opd_w / ((value / 100) * (value / 100))).toFixed(2)
      }
    },
  )

  const printDraftOPD = () => {
    // console.log('route.value.query.id', route.value.query.id)
    const routeData = router.resolve({
      name: 'printOpdDataDraft',
      query: { id: route.value.query.id },
    })
    window.open(routeData.href, '_blank')
    // router.push({
    //   name: 'printOpdDataDraft',
    //   query: { id: route.value.query.id },
    // })

  }

  return {
    printDraftOPD,
    data_list,
    statusAdd,
    cartListCheck,
    opdData,
    checkTab,
    getCheckingLoading,
    getCustomerLoading,
    customerData,
    tabs,
    smoking,
    booze,
    statusLoadDataChecking,
    checking_id,
    examinationList,
    search,
    statusText,
    isEditRoom,
    addCartLoding,
    cartLoading,
    cartCheck,
    saveChangeRoom,
    editorOption,
    removeLoading,
    isShowLabHistory,
    dataEdit,
    indexEdit,
    route,
    loadingUpdate,
    isShowCheckHistoryLasted,
    isShowNotiMessage,
    updateCheckLoading,
    isEditCheckList,
    isShowFaceCart,
    saveOPDLoading,
    serve_code,
    updateListCheck,
    saveListCheck,
    editCartListCheck,
    addListCheck,
    deleteListCheck,
    customFilter,
    saveOPD,
    serve_id,
    isCancel,
    commissionSelect,
    teamsAndUserList,
  }
}
